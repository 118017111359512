import clsx from "clsx";

type ImageType = {
  fetchPriority?: "auto" | "high" | "low" | "normal";
} & React.ImgHTMLAttributes<HTMLImageElement>;

export default function Image({
  className,
  loading,
  fetchPriority,
  onLoad,
  alt,
  ...rest
}: Readonly<ImageType>) {
  return (
    <img
      className={clsx(
        className || "",
        "transition-opacity duration-300 opacity-0"
      )}
      loading={loading || "lazy"}
      fetchpriority={fetchPriority || "low"}
      onLoad={(e) => {
        (e.target as HTMLImageElement).classList.add("!opacity-100");
        onLoad !== undefined && onLoad(e);
      }}
      alt={alt || ""}
      {...rest}
    />
  );
}
