import { Params, useParams, useRouteError } from "react-router-dom";
import Root from "./Root";
import { Image } from "@/components";

const serverErrorpage = ({ projectSlug }: Params<string>) => {
  return (
    <div className="relative h-dvh w-dvw">
      <Image
        src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
        className="absolute inset-0 object-cover object-top w-full h-full -z-10"
      />
      <div className="px-6 py-32 mx-auto text-center max-w-7xl sm:py-40 lg:px-8">
        <p className="text-base font-semibold leading-8 text-white">500</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
          Project {projectSlug} kan niet getoond worden
        </h1>
        <p className="mt-4 text-base text-white/70 sm:mt-6">
          Er is iets misgegaan bij het tonen van het project. Probeer het later
          opnieuw, of neem contact op met Amvest via{" "}
          <a
            href="mailto:info@amvest.nl"
            className="font-semibold leading-7 text-white underline"
          >
            info@amvest.nl
          </a>
        </p>
        <div className="flex justify-center mt-10">
          <a
            href="https://amvest.nl"
            className="text-sm font-semibold leading-7 text-white"
          >
            <span aria-hidden="true">&larr;</span> Naar de amvest.nl website
          </a>
        </div>
      </div>
    </div>
  );
};

const notFoundPage = ({ projectSlug }: Params<string>) => {
  return (
    <div className="relative h-dvh w-dvw">
      <Image
        src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
        className="absolute inset-0 object-cover object-top w-full h-full -z-10"
      />
      <div className="px-6 py-32 mx-auto text-center max-w-7xl sm:py-40 lg:px-8">
        <p className="text-base font-semibold leading-8 text-white">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
          Project {projectSlug} niet gevonden
        </h1>
        <p className="mt-4 text-base text-white/70 sm:mt-6">
          Sorry, het project dat je probeert te bezoeken kan niet gevonden
          worden.
        </p>
        <div className="flex justify-center mt-10">
          <a
            href="https://amvest.nl"
            className="text-sm font-semibold leading-7 text-white"
          >
            <span aria-hidden="true">&larr;</span> Naar de amvest.nl website
          </a>
        </div>
      </div>
    </div>
  );
};

export default function RootErrorPage() {
  const error: any = useRouteError();
  const params = useParams();

  return (
    <Root>
      {error.status >= 400 && error.status < 500 && notFoundPage(params)}
      {error.status >= 500 && error.status < 600 && serverErrorpage(params)}
    </Root>
  );
}
