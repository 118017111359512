import {
  Grid,
  HousetypeFilter,
  LayerNav,
  ListSection,
  MobilePopup,
  Preview,
  PriceFilter,
  ProjectMap,
  RoomFilter,
  StatusFilter,
  SunPositionFilter,
  SurfaceFilter,
} from "@/components";
import "@/components/Filter/Filter.css";
import SmallScreen from "@/components/Filter/SmallScreen";
import { PhaseLoader, PhaseQuery } from "@/loaders";
import {
  groupByLayer,
  groupPlotValuesByKey,
  keyBy,
  matchesFilters,
} from "@/services";
import { Hotspot, Layer, Plot } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState, useMemo } from "react";
import { useRouteLoaderData, useSearchParams } from "react-router-dom";
import { ConfigProvider } from "antd";
import useActiveLayer from "@/hooks/useActiveLayer";
import { useMediaQuery } from "usehooks-ts";

export default function PhasePage() {
  const { projectSlug, phaseSlug } = useRouteLoaderData("phase") as Awaited<
    ReturnType<ReturnType<typeof PhaseLoader>>
  >;

  const {
    data: { project, phase, statuses, plots, layers, hotspots },
  } = useSuspenseQuery(PhaseQuery(projectSlug, phaseSlug));

  /**
   * We're calculating a large amount of data here.
   * It's more efficient to do it in a single useMemo than it is to spread it out over multiple effects
   */
  const computed = useMemo(() => {
    const enabledFilters = Object.entries(phase.filters).reduce<string[]>(
      (accumulator, [key, enabled]) => {
        if (parseInt(enabled) === 0) return accumulator;

        accumulator.push(key);
        return accumulator;
      },
      []
    );

    const hotspotsByPlotId = phase.hotspots.reduce<{
      [key: Plot["id"]]: Hotspot;
    }>((accumulator, hotspot) => {
      if (hotspot.entity_type !== "App\\Models\\Plot") return accumulator;

      accumulator[hotspot.entity_id] = hotspot;
      return accumulator;
    }, {});

    return {
      enabledFilters: enabledFilters,
      plotValuesByKey: groupPlotValuesByKey(phase.plots),
      plotsByLayer: groupByLayer(phase.hotspots, layers, plots),
      hotspotsByPlotId: hotspotsByPlotId,
    };
  }, [phase.id]);

  const [searchParams] = useSearchParams();
  const activeHotspot = searchParams.get("hotspot")
    ? hotspots[searchParams.get("hotspot")!]
    : null;

  // Generate a string of valid filter params to use in hooks dependencies
  // So the hook reruns when a filter value changes
  const filterParamsString = useMemo(() => {
    const filterParams = searchParams;
    filterParams.delete("hotspot");
    filterParams.delete("layer");
    filterParams.delete("plot");
    return filterParams.toString() === "" ? null : filterParams.toString();
  }, [searchParams.toString()]);

  const initialFilteredPlots = keyBy(
    phase.plots.filter((plot) =>
      matchesFilters(plot, searchParams, statuses, computed.enabledFilters)
    ),
    "id"
  );
  const [filteredPlots, setFilteredPlots] = useState(initialFilteredPlots);

  useEffect(() => {
    setFilteredPlots(
      keyBy(
        project.plots.filter((plot) =>
          matchesFilters(plot, searchParams, statuses, computed.enabledFilters)
        ),
        "id"
      )
    );
  }, [filterParamsString]);

  const filteredPlotsByLayer = groupByLayer(
    phase.hotspots,
    layers,
    filteredPlots
  );

  const { activeLayer } = useActiveLayer(filteredPlotsByLayer);

  // logic for preview map for small devices
  const [showMap, setShowMap] = useState(useMediaQuery("(min-width: 1024px)"));
  const toggleMapVisibility = (force = false) => {
    setShowMap(force ? force : !showMap);
  };

  const handleResize = () => {
    setShowMap(window.innerWidth > 1024);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [intersecting, setIntersecting] = useState(
    Object.values(layers).reduce((acc, layer: Layer) => {
      acc[layer.id] = false;
      return acc;
    }, {} as { [key: number]: boolean })
  );

  const handleIntersecting = (layer: Layer, intersecting: boolean) => {
    setIntersecting((prev) => {
      return {
        ...prev,
        [layer.id]: intersecting,
      };
    });
  };

  return (
    <div className="pb-13">
      <ConfigProvider theme={{ cssVar: true }}>
        {/* Small screen */}
        <SmallScreen
          statuses={statuses}
          value={computed.plotValuesByKey}
          onClick={toggleMapVisibility}
          mapStatus={showMap}
          enabledFilters={computed.enabledFilters}
          className={clsx(
            "h-0 sticky top-5 left-0 right-0 z-50 lg:hidden",
            showMap && "!fixed"
          )}
        />

        {/* Big screen filters */}
        <div className="hidden sticky z-50 h-0 top-5 mx-auto max-w-grid lg:max-w-[calc(86rem+3.625rem)] lg:block">
          <div className="p-1 -translate-y-1/2 bg-white border rounded-md border-black/10 gap-x-1 md:flex-nowrap lg:shadow-filterBar lg:flex lg:px-5">
            <PriceFilter
              showMinPrice={true}
              values={Array.from(computed.plotValuesByKey.price.values())}
            />

            <HousetypeFilter
              values={Array.from(
                computed.plotValuesByKey.house_type_name.values()
              )}
            />

            <div className="w-px h-8 bg-black/10"></div>

            {computed.enabledFilters.includes("statuses") &&
              computed.plotValuesByKey.status.size > 0 && (
                <StatusFilter
                  values={Array.from(computed.plotValuesByKey.status.values())}
                  statuses={statuses}
                />
              )}

            {computed.enabledFilters.includes("room_count") &&
              computed.plotValuesByKey.room_count.size > 0 && (
                <RoomFilter
                  values={Array.from(
                    computed.plotValuesByKey.room_count.values()
                  )}
                />
              )}

            {computed.enabledFilters.includes("sun_position_outdoor") &&
              computed.plotValuesByKey.sun_position_outdoor.size > 0 && (
                <SunPositionFilter
                  values={Array.from(
                    computed.plotValuesByKey.sun_position_outdoor.values()
                  )}
                />
              )}

            {computed.enabledFilters.includes("living_surface") &&
              computed.plotValuesByKey.living_surface.size > 0 && (
                <SurfaceFilter
                  values={Array.from(
                    computed.plotValuesByKey.living_surface.values()
                  )}
                />
              )}
          </div>
        </div>
      </ConfigProvider>

      {/* Note: we can't toggle display:none/block instead of rerendering because Leaflet wont render properly */}
      {showMap && (
        <ProjectMap
          project={project}
          statuses={statuses}
          layers={layers}
          activeLayer={activeLayer}
          plotsById={plots}
          filteredPlotsByLayer={filteredPlotsByLayer}
          hotspots={hotspots}
          filteredPlots={filteredPlots}
          className={clsx(
            "fixed inset-0 z-40 overflow-hidden bg-secondary lg:block lg:relative lg:inset-auto"
          )}
        />
      )}

      <div
        className={clsx("relative bg-secondary lg:hidden", showMap && "hidden")}
      >
        <Preview
          background={activeLayer.background}
          handleClick={toggleMapVisibility}
        >
          Bekijk interactieve kaart
        </Preview>
      </div>

      {activeHotspot && (
        <MobilePopup
          plot={plots[activeHotspot.entity_id]}
          status={statuses[plots[activeHotspot.entity_id].status]}
          className="fixed z-40 pb-5 pl-6 pr-2 overflow-hidden bg-white bottom-12 pt-7 rounded-t-2xl lg:hidden"
        />
      )}

      {/* Plot sections */}
      <div
        className={clsx(
          "z-30 flex flex-col top-0 px-6 bg-white/90 backdrop-blur-xl lg:top-0 lg:sticky lg:px-0 lg:mb-12"
        )}
      >
        <div className="w-full mx-auto max-w-grid">
          <div className="pt-4 pb-6 lg:pt-10 lg:pb-6 lg:px-6 grid:px-0">
            <h1 className="font-extrabold h1">Aanbod</h1>

            {/* Layer navigation for desktop */}
            <LayerNav
              layers={phase.layers}
              toggleMapVisibility={toggleMapVisibility}
              plotsByLayer={computed.plotsByLayer}
              className="left-auto right-auto z-40 hidden mx-auto lg:w-auto lg:max-w-grid lg:sticky lg:top-40 lg:px-6 lg:block grid:px-0"
              intersectingSections={intersecting}
            />
          </div>

          <hr className="hidden my-0 border-black/10 lg:block" />
        </div>
      </div>

      <Grid className="flex flex-col gap-y-16 lg:gap-y-32">
        {phase.layers.map((layer) => (
          <ListSection
            key={layer.id}
            layer={layer}
            plots={computed.plotsByLayer[layer.id] || []}
            filteredPlots={filteredPlotsByLayer[layer.id] || []}
            statuses={statuses}
            hotspots={computed.hotspotsByPlotId}
            handleIntersecting={handleIntersecting}
          />
        ))}
      </Grid>

      {/* Layer navigation for mobile */}
      <LayerNav
        layers={phase.layers}
        toggleMapVisibility={toggleMapVisibility}
        plotsByLayer={computed.plotsByLayer}
        intersectingSections={intersecting}
        className={clsx(
          "sticky bottom-0 left-auto right-auto z-40 w-full mx-auto lg:hidden",
          showMap && "!fixed"
        )}
      />
    </div>
  );
}
