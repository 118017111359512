import { Button, Link as CustomLink, Grid, Small } from "@/components";
import "@/components/Filter/Filter.css";
import { SortableItem } from "@/components/Sortable/Sortableitem";
import WishlistForm from "@/components/WishlistForm";
import { PhaseQuery } from "@/loaders";
import { getFavoritePlots, keyBy } from "@/services";
import { Plot } from "@/types";
import {
  faArrowLeft,
  faInfoCircle,
  faLocationCrosshairs,
  faUpRightFromSquare,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
  MeasuringStrategy,
  DropAnimation,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import useFavorites from "@/hooks/useFavorites";

export default function Favorites() {
  const { projectSlug, phaseSlug } = useParams();

  const {
    data: {
      project,
      statuses,
      plots,
      layers,
      phase: { status, external_crm, external_crm_registration_link },
    },
  } = useSuspenseQuery(PhaseQuery(projectSlug!, phaseSlug!));
  const { favoritedIds, setFavoritedIds } = useFavorites();
  const favoritedPlots = getFavoritePlots(plots, statuses, favoritedIds);
  const hotspotsByEntityId = keyBy(project.hotspots, "entity_id");

  const [activeId, setActiveId] = useState<UniqueIdentifier>();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over === null || active.id === over.id) return;

    setFavoritedIds((id) => {
      const oldIndex = id.findIndex(
        (item) => item === parseInt(active.id as string)
      );
      const newIndex = id.findIndex(
        (item) => item === parseInt(over.id as string)
      );

      return arrayMove(id, oldIndex, newIndex);
    });
  };

  const handleUpClick = (current: number) => {
    setFavoritedIds((id) => arrayMove(id, current, current + 1));
  };

  const handleDownClick = (current: number) => {
    setFavoritedIds((id) => arrayMove(id, current, current - 1));
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const renderCard = (plot: Plot, className?: string) => {
    const hotspot = hotspotsByEntityId[plot.id];
    const layer = layers[hotspot.layer_id];

    return (
      <Small plot={plot} status={statuses[plot.status]} className={className}>
        <div className="relative grid auto-rows-min gap-x-4 gap-y-2 grid-cols-card lg:gap-y-6 lg:gap-x-6 xl:gap-x-8 2xl:gap-x-12">
          <div className="flex flex-wrap items-start gap-1 mt-2 col-span-full xs:gap-3 sm:col-start-2 sm:mt-6">
            <CustomLink
              to={`/projects/${projectSlug}/phases/${phaseSlug}?layer=${layer.id}&hotspot=${hotspot.id}#root`}
              state
              variant="primary"
              icon={faLocationCrosshairs}
              onClick={() => {
                // scrollToTopInstance
                //   .getTarget()
                //   ?.scrollIntoView({ behavior: "smooth" });
              }}
              className="grow sm:grow-0"
            >
              <span className="leading-normal whitespace-nowrap">
                Bekijk in gebouw
              </span>
            </CustomLink>

            <CustomLink
              to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${plot.id}#root`}
              variant="primary"
              icon={faInfoCircle}
              onClick={() => {
                // scrollToTopInstance
                //   .getTarget()
                //   ?.scrollIntoView({ behavior: "smooth" });
              }}
              className="grow sm:grow-0"
            >
              <span className="leading-normal whitespace-nowrap">
                Meer details
              </span>
            </CustomLink>
          </div>
        </div>
      </Small>
    );
  };

  const measuring = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  const dropAnimation: DropAnimation = {
    keyframes({ transform }) {
      return [
        { transform: CSS.Transform.toString(transform.initial) },
        {
          transform: CSS.Transform.toString({
            scaleX: 1,
            scaleY: 1,
            x: transform.final.x - 10,
            y: transform.final.y - 10,
          }),
        },
      ];
    },
    sideEffects: defaultDropAnimationSideEffects({
      className: {
        // active: pageStyles.active,
      },
    }),
  };

  return (
    <div>
      <Grid className="grid py-12 grid-cols-plotPage">
        <div className="mb-3 col-span-full lg:col-start-2 lg:col-end-24">
          <NavLink
            to={`/projects/${projectSlug}/phases/${phaseSlug}`}
            relative="path"
            className="flex items-center gap-x-2 hover:underline small"
          >
            <span className="text-lg">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="text-primary"
                size="lg"
              />
            </span>
            <span>Terug naar kaart</span>
          </NavLink>
        </div>

        <div className="col-span-full lg:col-start-2 lg:col-end-13">
          <section className="grid grid-cols-1 gap-4">
            <h1 className="mb-16 h1">Mijn favorieten</h1>

            <div className="grid pl-14 gap-y-8 lg:gap-y-16">
              {favoritedIds.length > 0 ? (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  modifiers={[restrictToVerticalAxis]}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  measuring={measuring}
                >
                  <SortableContext
                    items={favoritedIds}
                    strategy={verticalListSortingStrategy}
                  >
                    {favoritedPlots.available.map((plot, index) => {
                      return (
                        <SortableItem
                          key={plot.id}
                          id={plot.id.toString()}
                          index={index}
                          length={favoritedPlots.available.length}
                          onUpClick={() => handleUpClick(index)}
                          onDownClick={() => handleDownClick(index)}
                        >
                          {renderCard(plot)}
                        </SortableItem>
                      );
                    })}
                  </SortableContext>

                  <DragOverlay dropAnimation={dropAnimation}>
                    {activeId
                      ? renderCard(
                          plots[activeId],
                          "transition-all bg-white/75 backdrop-blur-lg"
                        )
                      : null}
                  </DragOverlay>
                </DndContext>
              ) : (
                <p className="body">
                  Er zijn nog geen beschikbare woningen geliked!
                </p>
              )}
            </div>
          </section>

          {favoritedPlots.unavailable.length > 0 && (
            <section className="grid grid-cols-1 gap-4 mt-8 lg:mt-12">
              <h2 className="h2">Niet beschikbare favorieten</h2>
              <p className="body">
                Onderstaande woningen zijn helaas niet beschikbaar.
              </p>
              {favoritedPlots.unavailable.map((plot) => renderCard(plot))}
            </section>
          )}
        </div>

        <div className="mt-12 text-balance col-span-full lg:col-start-15 lg:col-end-23 lg:mt-12">
          {status === "actief" ? (
            <div className="">
              <h2 className="mb-12 h2">Inschrijven</h2>
              {external_crm === "osre" ? (
                <>
                  <p className="!leading-7 body mb-2">
                    Heeft u voor uzelf op een rij welke huisnummers uw voorkeur
                    hebben, dan kunt u uw inschrijving starten in het
                    Woningdossier. U komt in het Woningdossier via onderstaande
                    button.
                  </p>
                  <p className="!leading-7 body font-bold mb-2">
                    Na het aanmelden c.q. inloggen, dient u opnieuw uw
                    voorkeuren te selecteren.
                  </p>
                  <p className="!leading-7 body mb-8">
                    Daarom is het raadzaam om uw favorietenlijst te noteren
                    voordat u start met inschrijven. Deze gegevens worden
                    namelijk niet automatisch ingevuld.
                  </p>

                  <Button
                    variant="primary"
                    icon={faUpRightFromSquare}
                    onClick={() =>
                      window.open(external_crm_registration_link, "_blank")
                    }
                  >
                    Naar het OSRE Woningdossier
                  </Button>
                </>
              ) : (
                <>
                  <p className="!leading-7 body mb-16">
                    Rangschik je favoriete woningen naar jouw persoonlijke top
                    5. Vervolgens kun je je via het onderstaande formulier
                    inschrijven voor jouw favoriete woningen.
                  </p>
                  <WishlistForm
                    enabled={favoritedPlots.available.length > 0}
                    className="grid grid-cols-8 gap-y-6"
                    favorites={favoritedPlots.available.slice(0, 5)}
                    availableStatus={statuses.beschikbaar}
                  />
                </>
              )}
            </div>
          ) : status === "voorbereiding" ? (
            <div className="">
              <h2 className="mb-12 h2">In voorbereiding</h2>
              <p className="!leading-7 body mb-2">
                Rangschik je favoriete woningen alvast in jouw persoonlijke top
                5. Zodra de {external_crm === "osre" ? "verhuur" : "verkoop"} is
                gestart, kun je je voor jouw favorieten inschrijven.
              </p>
            </div>
          ) : (
            <div className="">
              <h2 className="mb-12 h2">Inschrijven</h2>
              <p className="!leading-7 body mb-16">
                Op dit moment kun je je niet inschrijven.
              </p>
            </div>
          )}
        </div>
      </Grid>
    </div>
  );
}
