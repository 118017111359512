import { price as formatPrice, getCoordinates, isAvailable } from "@/services";
import { Hotspot, Media, Phase, Plot } from "@/types";
import {
  faBedAlt,
  faCompass,
  faTimes,
  faVectorSquare,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LatLngBounds } from "leaflet";
import { useRef } from "react";
import { Popup as LeafletPopup, useMapEvents } from "react-leaflet";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Button from "./Button";
import Placeholder from "./Placeholder";
import StatusLabel from "./StatusLabel";
import { formatName } from "@/services/Plot";
import { useMediaQuery } from "usehooks-ts";
import Image from "./Image";

type PopupProps = {
  loaded: boolean;
  hotspot: Hotspot | null;
  plot: Plot | null;
  statuses: Phase["statuses"];
  background: Media;
};

export default function DesktopPopup({
  loaded,
  plot,
  hotspot,
  statuses,
  background,
}: Readonly<PopupProps>) {
  const onClose = () => {
    searchParams.delete("hotspot");
    setSearchParams(searchParams);
  };

  const ref = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectSlug, phaseSlug } = useParams();
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  useMapEvents({
    // We can't use popupclose because it's fired multiple times, for some reason
    // popupclose: () => onClose(),
    // We can't use popupopen because it's rarely fired
    // popupopen: () => console.log("popupopen"),
    click: () => onClose(),
  });

  if (!loaded || !isDesktop || !plot || !hotspot) return null;

  const {
    id,
    attachments,
    name,
    price,
    room_count,
    sun_position_outdoor,
    living_surface,
    status,
  } = plot;

  const coordinates = getCoordinates(hotspot, background);

  if (!coordinates) {
    console.error("No coordinates found for hotspot", hotspot, coordinates);
    return null;
  }

  const position = new LatLngBounds(coordinates).getCenter();
  const formattedPrice = formatPrice(plot);

  return (
    <LeafletPopup
      position={position}
      closeButton={false}
      autoClose={false}
      closeOnClick={false}
      closeOnEscapeKey={false}
      minWidth={220}
      className="!m-0"
      autoPanPadding={[38, 38]}
    >
      <aside ref={ref} className="relative flex flex-col bg-white shadow-popup">
        <Button
          variant="primary"
          className="absolute w-8 h-8 p-2 rounded-full bg-white/50 right-2 top-2 aspect-square hocus:bg-secondary hocus:!text-secondary-contrast"
          onClick={() => onClose()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>

        <Link
          to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${id}#root`}
        >
          {attachments.length > 0 && attachments[0]?.original ? (
            <Image
              src={attachments[0].original}
              srcSet={attachments[0].srcset}
              sizes="224px"
              alt={name}
              width="600"
              height="400"
              className="object-cover object-center w-full h-full aspect-video"
            />
          ) : (
            <Placeholder className="object-cover object-center w-full h-full" />
          )}

          <div className="flex flex-col p-4 gap-y-3">
            <div className="flex flex-col">
              <span className="font-bold h4">{formatName(plot)}</span>

              <span className="flex justify-between small">
                {price && isAvailable(statuses[status]) && (
                  <span className="flex gap-x-1">
                    <span>{formattedPrice}</span>
                  </span>
                )}

                <StatusLabel status={statuses[status]} />
              </span>
            </div>

            <ul className="flex flex-col gap-y-1">
              <li>
                <hr />
              </li>

              {room_count && (
                <li>
                  <div className="flex items-center h-6 gap-x-3">
                    <span className="text-base !leading-none text-primary">
                      <FontAwesomeIcon icon={faBedAlt} />
                    </span>
                    <span className="small">{room_count} kamers</span>
                  </div>
                </li>
              )}

              <li>
                <hr />
              </li>

              {sun_position_outdoor && (
                <li>
                  <div className="flex items-center h-6 gap-x-3">
                    <span className="text-base !leading-none text-primary">
                      <FontAwesomeIcon icon={faCompass} />
                    </span>
                    <span className="small">{sun_position_outdoor}</span>
                  </div>
                </li>
              )}

              <li>
                <hr />
              </li>

              {living_surface && (
                <li>
                  <div className="flex items-center h-6 gap-x-3">
                    <span className="text-base !leading-none text-primary">
                      <FontAwesomeIcon icon={faVectorSquare} />
                    </span>
                    <span className="small">
                      {living_surface}m<sup>2</sup>
                    </span>
                  </div>

                  <hr />
                </li>
              )}
            </ul>
          </div>
        </Link>
      </aside>
    </LeafletPopup>
  );
}
