import { sortAlphabetically } from "@/services";
import clsx from "clsx";
import LayerNavLink from "./LayerNavLink";
import { Layer, Plot } from "@/types";
import { useNavigate } from "react-router-dom";

type LayerNavProps = {
  layers: Layer[];
  toggleMapVisibility: (show: boolean) => void;
  plotsByLayer: { [key: Layer["id"]]: Plot[] };
  className?: string;
  intersectingSections: { [key: Layer["id"]]: boolean };
};

export default function LayerNav({
  layers,
  toggleMapVisibility,
  plotsByLayer,
  className,
  intersectingSections,
}: LayerNavProps) {
  const navigate = useNavigate();

  const handleClick = (layer: Layer) => {
    toggleMapVisibility(true);

    navigate(`#layer-${layer.slug}`);
  };

  return (
    <nav className={clsx(className)}>
      <div className="flex justify-start border border-black/10 shadow-layerNavBar max-w-[100dvw] lg:rounded-md lg:max-w-fit">
        <div className="flex items-center pl-6 pr-4 font-bold bg-white text-primary shrink-0 lg:rounded-l-md">
          <span className="text-sm lg:body">Woonlaag</span>
        </div>

        <ul className="flex items-center flex-grow py-2 pl-4 overflow-x-auto border-l border-l-black/10 gap-x-1 bg-white/65 backdrop-blur-xl flex-nowrap lg:rounded-r-md lg:p-2">
          {layers
            .toSorted((a, b) => sortAlphabetically(a.slug, b.slug))
            .map((layer) => {
              return (
                <LayerNavLink
                  key={layer.id}
                  layer={layer}
                  active={intersectingSections[layer.id] === true}
                  inactive={
                    !Object.keys(plotsByLayer).includes(layer.id.toString())
                  }
                  onClick={() => handleClick(layer)}
                />
              );
            })}
        </ul>
      </div>
    </nav>
  );
}
